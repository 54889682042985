import { configureStore } from '@reduxjs/toolkit';
import myReducer from './slice';

const store = configureStore({
  reducer: myReducer,
})

export default store;

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch