import React, { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Box, LinearProgress, ToggleButton, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { setAction, setPositionSettings } from '../../api/bot.api';
import { displayWithNonZeroDecimals, shortenAddress } from '../../libs/utils';
import ActionMenu from './ActionMenu';
import { OrcaPositionInterface, PositionSettingsInterface, TabInterface } from './types';
import { useDispatch } from 'react-redux';
import { setPositionSettingsAction } from '../../store/slice';
import PSModal from './PSModal';


interface OrcaPositionTableProps {
    tabData: TabInterface;
}

const OrcaPositionTable: React.FC<OrcaPositionTableProps> = ({ tabData }) => {
    const [psOpen, setPsOpen] = useState(false);
    const [settingsInfo, setSettingsInfo] = useState<OrcaPositionInterface>();
    const [settingsTabId, setSettingsTabId] = useState<string>('');

    const dispatch = useDispatch()

    const handleChangePosition = async (key: keyof PositionSettingsInterface, settings: PositionSettingsInterface | undefined) => {
        if(!settings){
            console.log("No position settings");
            return;
        }
        const temp = {...settings, [key]: !settings[key]};
        const res = await setPositionSettings(temp);
        if(res.success){
            toast.success(`Changed Position settings`);
            dispatch(setPositionSettingsAction({tabId: tabData._id, positionMint: settings.positionMint, settings: temp}))
        }else{
            toast.error(res.msg);
        }
    }

    const handleAction = async (tabId: string, info: OrcaPositionInterface, action: string) => {
        if(action === 'settings'){
            setSettingsInfo(info);
            setSettingsTabId(tabId);
            setPsOpen(true);
            return;
        }
        if(action === 'deposit'){
            
            return;
        }
        const res = await setAction({tabId, positionMint: info.positionMint, action});
        if(res.success){
            toast.success(`Added ${action} command`);
        }else{
            toast.error(res.msg);
        }
    }

    const handleApplySettings = async (settings: PositionSettingsInterface) => {
        let res;
        res = await setPositionSettings(settings);
        if(res.success){
            toast.success(`Applied position settings`);
            dispatch(setPositionSettingsAction({tabId: tabData._id, positionMint: settings.positionMint, settings: settings}))
            if(settings.rebalanceNow){
                res = await setAction({tabId: settingsTabId, positionMint: settings.positionMint, settings, action: 'rebalance'});
                if(res.success){
                    toast.success(`Added rebalance now command`);
                }else{
                    toast.error(res.msg);
                }
            }
        }else{
            toast.error(res.msg);
        }
        
    }

    return (
        <Box mt={1} pb={1} sx={{display: 'flex', flexDirection: 'column', width: '100%', overflowY: 'auto',
            '& table': {
                border: '1px solid blue',
                borderColor: 'divider',
                borderRadius: '12px 12px 0 0',
                thead: {
                    height: '50px',
                },
                th: {
                  color: 'text.disabled',  
                },
                tr: {
                    height: '70px'
                },
                td: {
                    textAlign: 'center',
                }
            },
        }}>
            <PSModal open={psOpen} posInfo={settingsInfo} onClose={()=>setPsOpen(false)} onApplySettings={handleApplySettings}/>
            <table>
                <thead>
                    <tr>
                        <th>Pool</th>
                        <th>Address</th>
                        <th>Balance</th>
                        <th>Current Price</th>
                        <th>Price Range</th>
                        <th>TokenA</th>
                        <th>TokenB</th>
                        <th>Pending Yield</th>
                        <th>Auto _</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tabData.positions.map((info) => (
                        <tr key={info.positionMint}>
                            <td style={{maxWidth: '100px'}}>{info.symbolA}/{info.symbolB}</td>
                            <td style={{maxWidth: '100px'}}>{shortenAddress(info.positionMint, 4)}</td>
                            <td>${displayWithNonZeroDecimals(info.balance, 1)}</td>
                            <td>{displayWithNonZeroDecimals(info.poolPrice, 2)} {info.symbolB}/{info.symbolA}</td>
                            <td>
                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Box sx={{width: '10px', height: '10px', borderRadius: '50%', backgroundColor: info.active ? 'green' : 'red', marginRight: '8px'}}></Box>
                                    {displayWithNonZeroDecimals(info.lowerPrice, 2)}~{displayWithNonZeroDecimals(info.upperPrice, 2)}
                                </Box>
                            </td>
                            <td>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <Typography>{displayWithNonZeroDecimals(info.amountA, 1)}</Typography>
                                    <Typography>{displayWithNonZeroDecimals(info.ratio, 1)}%</Typography>
                                </Box>
                            </td>
                            <td>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <Typography>{displayWithNonZeroDecimals(info.amountB, 1)}</Typography>
                                    <Typography>{displayWithNonZeroDecimals(100 - info.ratio, 1)}%</Typography>
                                </Box>
                            </td>
                            <td>{ info.totalFee && (info.totalFee < 0.01) ? '<$0.01' : `$${displayWithNonZeroDecimals(info.totalFee, 1)}`}</td>
                            <td style={{width: '200px', paddingLeft: '20px'}}>
                                <Box sx={{display: 'flex', justifyContent: 'center'}} gap={1}>
                                    <ToggleButton sx={{padding: '4px', color: 'divider'}} value="autoCollect" selected={info.settings?.autoCollect} onChange={() => handleChangePosition('autoCollect', info.settings)}>
                                        <CheckIcon fontSize='small'/>
                                        <Typography>Col</Typography>
                                    </ToggleButton>
                                    <ToggleButton sx={{padding: '4px', color: 'divider'}} value="autoRebalance" selected={info.settings?.autoRebalance} onChange={() => handleChangePosition('autoRebalance', info.settings)}>
                                        <CheckIcon fontSize='small'/>
                                        <Typography>Reb</Typography>
                                    </ToggleButton>
                                    <ToggleButton sx={{padding: '4px', color: 'divider'}} value="feesToRebalance" selected={info.settings?.feesToRebalance} onChange={() => handleChangePosition('feesToRebalance', info.settings)}>
                                        <CheckIcon fontSize='small'/>
                                        <Typography>FtR</Typography>
                                    </ToggleButton>
                                </Box>
                            </td>
                            <td style={{maxWidth: "100px"}}>
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                    {!info.status && <ActionMenu handleAction={(action)=>handleAction(tabData._id, info, action)}/>}
                                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%'}}>
                                        { info.status &&
                                            <Typography variant='subtitle2'>{info.status?.currentAction} <br/> {info.status?.toAction}</Typography>
                                        }
                                        { info.status?.currentAction === 'doing' && 
                                            <LinearProgress color='secondary'/>
                                        }
                                    </Box>
                                </Box>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Box>
    )
}

export default OrcaPositionTable;
