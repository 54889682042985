import { Box, Divider, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { setSettings } from '../../api/bot.api';
import { SettingsInterface } from '../homepage/types';
import { LoadingButton } from '@mui/lab';
import { setSettingsAction } from '../../store/slice';
import { useDispatch } from 'react-redux';

interface SettingsPopoverProps {
    settings: SettingsInterface;
}

export default function SettingsPopover({ settings }: SettingsPopoverProps){
    const [newSettings, setNewSettings] = useState<SettingsInterface>(settings);
    const [isSaving, setIsSaving] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const dispatch = useDispatch();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // const marks = [
    //     {
    //         value: Number(newSettings.percentOffset),
    //         label: `${newSettings.percentOffset}%`,
    //     },
    //     {
    //         value: (100 - newSettings.percentOffset),
    //         label: `${100 - newSettings.percentOffset}%`,
    //     },
    // ];
    
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(()=> {
        if(anchorEl){
            console.log("settings:", settings);
            setNewSettings(settings);
        }
    }, [settings, anchorEl])

    const onChange = (key: string, value: any) => {
        const temp = {...newSettings, [key]: value};
        setNewSettings(temp);
    }

    const onInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const temp = e.target.value;
        if(!isNaN(Number(temp))){
            if(e.target.name === 'percentOffset' && Number(temp) > 20){
                return;
            }
            onChange(e.target.name, temp);
        }
    }

    const handleApply = async () => {
        setIsSaving(true);
        const res = await setSettings(newSettings);
        setIsSaving(false);
        if(res.success){
            dispatch(setSettingsAction(res.data))
            toast.success("Applied settings successfully");
            handleClose();
        }else{
            toast.error(res.msg);
        }
    }

    return (
        <>
            <Button aria-describedby={id} variant="outlined" onClick={handleClick}>
                App Settings
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth:' 100%'}} px={4} py={5}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-evenly'}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={newSettings.autoCollect}
                                    onChange={(e)=>onChange("autoCollect", e.target.checked)}
                                />
                            } label="Auto Collect" />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={newSettings.autoRebalance}
                                    onChange={(e)=>onChange("autoRebalance", e.target.checked)}
                                />
                        } label={"Auto Rebalance"} />
                    </Box>

                    <Divider textAlign="left" sx={{mt: 3, mb: 2}}>Auto Collect When</Divider>

                    {/* <Box px={1}>
                        <TextField label="Percent Offset" variant="outlined" size="small" name='percentOffset' value={newSettings.percentOffset} onChange={onInputValueChange}/>
                        <Slider
                            track="inverted"
                            aria-labelledby="track-inverted-range-slider"
                            valueLabelDisplay="auto"
                            value={[Number(newSettings.percentOffset), 100 - newSettings.percentOffset]}
                            marks={marks}
                            sx={{mt: 1}}
                        />
                    </Box> */}
                    <Box display='flex' sx={{ gap: 2 }} mt={1} alignItems={'center'}>
                        <Typography>Pending Yield {'>'}</Typography>
                        <TextField label="Max Fee" variant="outlined" size="small" name='maxFee' value={newSettings.maxFee} onChange={onInputValueChange}/>
                    </Box>
                    <Box mt={3} display='flex' justifyContent={'center'}>
                    <LoadingButton loading={isSaving} loadingIndicator="Applying..." variant="outlined" onClick={handleApply}>
                        Apply Settings
                    </LoadingButton>
                    </Box>
                </Box>
            </Popover>
        </>
    );
}
