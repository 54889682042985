import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'; // Import PropTypes

import { Box } from '@mui/material';

import Loading from './Loading';
import { userCheckTkn } from '../api/user.api';

const ProtectedRoute = (props) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkToken = async () => {
            setIsLoading(true);

            const res = await userCheckTkn();

            if (!res.success) {
                localStorage.removeItem('tkn');
                navigate('/signin');
            }else{
                localStorage.setItem('username', res.data.username);
                setIsLoading(false);
            }
        };

        const tkn = localStorage.getItem('tkn');

        if (tkn) checkToken();
        else navigate('/signin');
    }, [navigate]);

    return isLoading ? <Loading /> : <Box sx={{ height: '100vh' }}>{props.children}</Box>;
};
ProtectedRoute.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
