import axiosClient from './axios.client';

export const userSignUp = async ({ username, password }) => {
    const response = await axiosClient.post('users/signup', { username, password });
    return response.data;
};

export const userSignIn = async ({ username, password }) => {
    const response = await axiosClient.post('users/signin', { username, password });
    return response.data;
};

export const userCheckTkn = async () => {
    const response = await axiosClient.get('users/check-token');
    return response.data;
};
