import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addTab } from '../../api/bot.api';
import { createKeypairFromString } from '../../libs/utils';
import KeyInput from './KeyInput';
import { ChainIds } from '../../libs/constants';
import { addTabAction } from '../../store/slice';

export default function AddTabModal() {
    const [open, setOpen] = React.useState(false);
    const [random, setRandom] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [error, setError] = useState<string>('')
    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const onKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError('');
        setRandom(e.target.value);
        const tempWallet = createKeypairFromString(e.target.value);
        if(tempWallet){
            setAddress(tempWallet.publicKey.toString());
        }else{
            setAddress("");
        }
    }

    const onSetKey = async () => {
        const tempWallet = createKeypairFromString(random);
        if(!tempWallet){
            setError("Please input valid private key")
            setAddress('');
            return;
        }
        const res = await addTab({ random: random, chainId: ChainIds.SOLANA });
        if (res.success) {
            const data = res.data;
            console.log("added new tab:", data)
            dispatch(addTabAction({...data}))
            handleClose();
            toast.success('Added new Wallet');
        }else{
            toast.error(res.msg);
        }
    }

    return (
        <>
            <Button onClick={handleOpen} variant='outlined'>+ Add Wallet</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '800px', maxWidth:' 100%',
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <KeyInput value={random} onChange={onKeyChange} />
                    {error !== '' && <label style={{ color: 'red', fontSize: '14px' }}>{error}</label>}
                    {address !== '' && <Typography variant="body2" mt={1}>Chosen address: {address}</Typography>}
                    <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                        <Button variant='outlined' onClick={onSetKey}>Connect</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
