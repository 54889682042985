import { ApiResponseType } from '../components/homepage/types';
import { encryptKey } from '../libs/utils';
import axiosClient from './axios.client';

export const refreshTab = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/refresh-tab', data);
    return response.data;
};

export const addTab = async (data: any): Promise<ApiResponseType> => {
    data.random = encryptKey(data.random);
    const response = await axiosClient.post<ApiResponseType>('bot/add-tab', data);
    return response.data;
};

export const closeTab = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/close-tab', data);
    return response.data;
};

export const getTabs = async (): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/get-tabs');
    return response.data;
};

export const getSettings = async (): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/get-settings');
    return response.data;
};

export const setSettings = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/set-settings', data);
    return response.data;
};

export const setTabSettings = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/set-tab-settings', data);
    return response.data;
};

export const setPositionSettings = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/set-position-settings', data);
    return response.data;
};

export const setAction = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/set-action', data);
    return response.data;
};

export const getHistories = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/get-histories', data);
    return response.data;
};

export const getReport = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/get-report', data);
    return response.data;
};

export const setHistoryAction = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/set-history-action', data);
    return response.data;
};
export const getLog = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/get-log', data);
    return response.data;
};
export const getNfts = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/get-nfts', data);
    return response.data;
};

export const swapNft = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/swap-nft', data);
    return response.data;
};

export const sendNft = async (data: any): Promise<ApiResponseType> => {
    const response = await axiosClient.post<ApiResponseType>('bot/send-nft', data);
    return response.data;
};