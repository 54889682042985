import React from 'react';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import HistoryContainer from '../components/historyPage/HistoryContainer';

const HistoryPage = () => {
    const navigate = useNavigate();

    const onSignOut = () => {
        localStorage.removeItem('tkn');
        navigate('/signin');
    };

    return (
        <Stack alignItems="center" sx={{flex: 1, height: '100%'}}>
            <Header bg borderBottom>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        paddingX: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h5" fontWeight="600" color="#ccffff" ml={2}>
                        Auto Platform
                    </Typography>
                    <Button
                        onClick={() => navigate('/report')}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '200px',
                            transform: 'translateY(-50%)',
                            mr: 4,
                        }}
                    >
                        <Typography variant="button" color="#ccffff">
                            View Report
                        </Typography>
                    </Button>
                    <Button
                        onClick={() => navigate('/')}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '56px',
                            transform: 'translateY(-50%)',
                            mr: 4,
                        }}
                    >
                        <Typography variant="button" color="#ccffff">
                            To Home
                        </Typography>
                    </Button>
                    <IconButton
                        onClick={onSignOut}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '16px',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <LogoutOutlinedIcon />
                    </IconButton>
                </Box>
            </Header>
            <HistoryContainer/>
        </Stack>
    );
};

export default HistoryPage;
