import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Container, Typography } from '@mui/material';
import PropTypes from 'prop-types'; // Import PropTypes

import Header from './Header';
import Loading from './Loading';
import { userCheckTkn } from '../api/user.api';

const AuthRoute = (props) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkToken = async () => {
            setIsLoading(true);

            const res = await userCheckTkn();

            if (!res.success) {
                localStorage.removeItem('tkn');
                setIsLoading(false);
            }
            if (res.success) return navigate('/');
        };

        const tkn = localStorage.getItem('tkn');

        if (tkn) checkToken();
        else setIsLoading(false);
    }, [navigate]);

    return isLoading ? (
        <Loading />
    ) : (
        <Container
            component="main"
            maxWidth="md"
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Header>
                <Typography variant="h5" fontWeight="600" color="#ccffff" mt={'20vh'}>
                    Auto Platform
                </Typography>
            </Header>

            <Box width="100%">{props.children}</Box>

            <Box padding={2}>
                <Typography variant="caption" color="primary">
                </Typography>
            </Box>
        </Container>
    );
};

AuthRoute.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthRoute;
