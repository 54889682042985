import React, {useEffect, useState} from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Slider, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { OrcaPositionInterface, PositionSettingsInterface } from './types';
import { displayWithNonZeroDecimals } from '../../libs/utils';
import CurrencySelect from './CurrencySelect';

interface PSProps {
    posInfo?: OrcaPositionInterface;
    open: boolean;
    onClose: () => void;
    onApplySettings: (settings: PositionSettingsInterface) => void;
}

export const defaultPositionSettings: PositionSettingsInterface = {
    positionMint: '',
    autoCollect: true, 
    autoRebalance: true, 
    feesToRebalance: false,
    nextRangeSize: 0,
    depositPercent: 50,
    maxDepositAmount: 0,
    stopCheck: false,
    stopBelowA: 0,
    stopBelowB: 0,
    stopConvertCurrency: '',
    harvestCurrency: '',
    rebalanceNow: false,
    isUsed: false,
};

const numberFields = ["depositPercent", "nextRangeSize", "maxDepositAmount", "stopBelowA", "stopBelowB"]

export default function PSModal({ open, onClose, posInfo, onApplySettings}: PSProps) {
    const [settings, setSettings] = useState<PositionSettingsInterface>(defaultPositionSettings);

    const handleClose = () => onClose();
    const handleChangeSettings = (key: keyof PositionSettingsInterface, value: number | number[] | string | boolean) => {
        if (Array.isArray(value)) {
            return;
        }
        if (numberFields.includes(key as string)) {
            const numValue = Number(value);
            if (!isNaN(numValue)) {
                setSettings({...settings, [key]: value});
            }
        } else {
            setSettings({...settings, [key]: value});
        }
    };

    const handleApply = () => {
        onApplySettings(settings);
        onClose();
    }

    useEffect(() => {
        if(posInfo?.settings){
            setSettings({...defaultPositionSettings, ...posInfo.settings});
        }
    }, [posInfo])

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',
                    width: '100vw', maxWidth: '550px', height: 'auto', maxHeight: '100vh', overflowY: 'auto',
                    position: 'absolute',
                    top: '50%',
                    right: '20%',
                    transform: 'translate(0, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    px: 2,
                    py: 2,
                }}>
                    <Typography variant='h6'>{posInfo?.symbolA}/{posInfo?.symbolB}</Typography>
                    <Typography sx={{my: 0.5}}>Current Range Size</Typography>
                    <Typography  variant='body1'>{displayWithNonZeroDecimals(posInfo?.rangeWidth, 1)}</Typography>
                    <Typography sx={{my: 0.5}}>Next Range Size Desired</Typography>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <TextField size='small' value={settings.nextRangeSize} sx={{width: '100px'}} onChange={(e)=>handleChangeSettings('nextRangeSize', e.target.value)}/>
                    </Box>
                    <Typography sx={{mt: 4, mb: 0.5}}>Weighted Rebalance Adjustor</Typography>
                    <Slider value={settings.depositPercent} valueLabelDisplay="auto"  min={10} max={90} onChange={(event, newValue)=>handleChangeSettings('depositPercent', newValue)}/>
                    <Stack flexDirection='row' gap={4} mt={0.5}>
                        <Stack>
                            <Typography>{posInfo?.symbolA}</Typography>
                            <TextField size='small' value={ `${settings.depositPercent}%`} disabled/>
                        </Stack>
                        <Stack>
                            <Typography>{posInfo?.symbolB}</Typography>
                            <TextField size='small' value={ `${100 - settings.depositPercent}%`}  disabled/>
                        </Stack>
                    </Stack>
                    <Typography sx={{mt: 3}}>Maximum Iteration Amount</Typography>
                    <Typography variant='body2'>Enter the maximum amount of money in USD terms that should be deposited into a new pool position over and
                        over until all funds are deposited. If set to 0 then 70% of the funds will be desposited followed by the rest.
                    </Typography>
                    <Box  sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                        {"<"} <TextField size='small' value={settings.maxDepositAmount} sx={{mx: 2}} onChange={(e)=>handleChangeSettings('maxDepositAmount', e.target.value)}/> USD
                    </Box>
                    <Box sx={{display: 'flex', mt: 4, justifyContent: 'space-between'}}>
                        <Box sx={{mr: 2, mt: 0.8}}>
                            <Checkbox checked={settings.stopCheck} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} onChange={(e)=>handleChangeSettings('stopCheck', e.target.checked)} />
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center' , mt: 1}}>
                                <Typography>Stop Pool if {posInfo?.symbolA} below</Typography>
                                <TextField size='small' value={settings.stopBelowA} sx={{mx: 2, width: '150px'}} onChange={(e)=>handleChangeSettings('stopBelowA', e.target.value)}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1}}>
                                <Typography>Or {posInfo?.symbolB} below</Typography>
                                <TextField size='small' value={settings.stopBelowB} sx={{mx: 2, width: '150px'}} onChange={(e)=>handleChangeSettings('stopBelowB', e.target.value)}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1}}>
                                <Typography>If stop, convert all currency to</Typography>
                                <CurrencySelect value={settings.stopConvertCurrency} sx={{mx: 2, width: '150px'}} onChange={(e)=>handleChangeSettings('stopConvertCurrency', e.target.value)}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 3, width: '100%'}}>
                        <Typography sx={{ml: 6}}>Harvest Currency</Typography>
                        <CurrencySelect value={settings.harvestCurrency} sx={{ml: 2, width: '200px'}} onChange={(e)=>handleChangeSettings('harvestCurrency', e.target.value)}/>
                    </Box>
                    <Box sx={{mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={settings.rebalanceNow} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} onChange={(e)=>handleChangeSettings('rebalanceNow', e.target.checked)} />} label="Rebalance Now" />
                        </FormGroup>
                        <Typography variant='body2'>
                            Check this box to perform a rebalance when you apply settings. Otherwise the settings will be applied on the next rebalance.
                        </Typography>
                    </Box>
                    <Stack flexDirection={'row'} gap={6} mt={2}>
                        <Button variant='outlined'  onClick={()=>handleApply()}>Apply Settings</Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}
