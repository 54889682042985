// This file stores web3 related constants such as addresses, token definitions, ETH currency references and ABI's

export enum ChainIds {
    POLYGON = 137,
    ARBITRUM = 42161,
    SOLANA = 900,
}


export interface ConfigInterface {
    rpc: { [key : string]: string }
}

export const CurrentConfig: ConfigInterface = {
    rpc: {
        [ChainIds.POLYGON]:
            'https://polygon-mainnet.g.alchemy.com/v2/ZTM53Wl1cyj2D-wO_XswyE0EWGmMSZsP',
        [ChainIds.ARBITRUM]:
            'https://arb-mainnet.g.alchemy.com/v2/sK1aYqcFNRA28MXADmpfIFh2KWY338v5',
        [ChainIds.SOLANA]:
            "https://mainnet.helius-rpc.com/?api-key=bb956f7e-055d-40e0-9f31-d3e79526eaf8"
    },
}