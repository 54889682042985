import axios from 'axios';
import queryString from 'query-string';

const baseURL = process.env.REACT_APP_BACKEND;
const axiosClient = axios.create({
    baseURL,
    paramsSerializer: {
        encode: (params) => queryString.stringify(params),
    },
});

axiosClient.interceptors.request.use(async (config) => {
    return {
        ...config,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('tkn')}`,
        },
    };
});

axiosClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (_err) => {
        return {data: { success: false, msg: "Error on requesting to server" }}
    },
);

export default axiosClient;
