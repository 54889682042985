import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface CurrencySelect {
    value: string;
    onChange: (event: SelectChangeEvent) => void;
    sx?: any;
}

const CurrencySelect: React.FC<CurrencySelect> = ({value, onChange, sx}) => {

    const { tabs } = useSelector((state: RootState) => state);
    
    const currencyOptions = React.useMemo(() => {
        const uniqueCurrencies = new Set<string>();
        tabs.forEach(tab => {
            if (tab.balance) {
                Object.keys(tab.balance).forEach(currency => uniqueCurrencies.add(currency));
            }
        });
        // Sort the currencies alphabetically
        const sortedCurrencies = Array.from(uniqueCurrencies).sort((a, b) => a.localeCompare(b));
        return sortedCurrencies;
    }, [tabs]);

    return (
        <FormControl sx={{ minWidth: 120, ...sx }} size="small">
            <Select
                value={value}
                onChange={onChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem value={""} sx={{height: '30px'}}></MenuItem>
                {currencyOptions.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                        {currency}
                    </MenuItem>
                ))}
            </Select>
      </FormControl>
    );
}
export default CurrencySelect;